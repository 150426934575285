// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-belajar-coding-js": () => import("./../src/pages/belajar-coding.js" /* webpackChunkName: "component---src-pages-belajar-coding-js" */),
  "component---src-pages-blog-js": () => import("./../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-faq-js": () => import("./../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-js": () => import("./../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-serverless-js": () => import("./../src/pages/serverless.js" /* webpackChunkName: "component---src-pages-serverless-js" */),
  "component---src-pages-updates-js": () => import("./../src/pages/updates.js" /* webpackChunkName: "component---src-pages-updates-js" */),
  "component---src-templates-blog-post-index-js": () => import("./../src/templates/BlogPost/index.js" /* webpackChunkName: "component---src-templates-blog-post-index-js" */),
  "component---src-templates-lesson-content-index-js": () => import("./../src/templates/LessonContent/index.js" /* webpackChunkName: "component---src-templates-lesson-content-index-js" */),
  "component---src-templates-lesson-section-index-js": () => import("./../src/templates/LessonSection/index.js" /* webpackChunkName: "component---src-templates-lesson-section-index-js" */),
  "component---src-templates-serverless-post-index-js": () => import("./../src/templates/ServerlessPost/index.js" /* webpackChunkName: "component---src-templates-serverless-post-index-js" */)
}

