import styled from 'styled-components';
import tw from 'twin.macro';
import { motion } from 'framer-motion';

export const Button = motion.custom(styled.button`
  outline: none !important;
  ${tw`border border-purple-600 text-white shadow-lg px-4 py-2 mb-2`};

  ${({ primary }) => (primary ? tw`bg-purple-600` : tw`text-purple-500 `)};

  ${({ block }) => block && tw`w-full sm:w-1/3`};
`);
